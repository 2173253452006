import React, { useState } from 'react';
import img from '../images/social/facebook.png';
import img1 from '../images/social/x.png';
import img2 from '../images/social/linkedin.png';
import img3 from '../images/social/instagram.png';
import img4 from '../images/social/youtube.png';
import img5 from '../images/social/tiktok.png';
import img6 from '../images/free_hydration3.png';
import '../css/style.css';
import {useLocation, Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isActive = (pathname) => location.pathname === pathname;
  
  return (
    <>
      <header className="header-main-p1">
        <div className="text-end header-container">
          <Link
            to="https://www.facebook.com/profile.php?id=61564981720392"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={img}
              alt="Facebook"
              className="IMG1"
            />
          </Link>
          <Link
            to="https://x.com/Free_Hydration"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={img1}
              alt="Twitter"
              className="IMG2"
            />
          </Link>
          <Link
            to="https://www.linkedin.com/in/free-hydration"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={img2}
              alt="LinkedIn"
              className="IMG2"
            />
          </Link>
          <Link
            to="https://www.instagram.com/free_hydration/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={img3}
              alt="Instagram"
              className="IMG2"
            />
          </Link>
          <Link
            to="https://www.youtube.com/@Free_Hydration"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={img4}
              alt="YouTube"
              className="IMG2"
            />
          </Link>
          <Link
            to="https://www.tiktok.com/@free_hydration?is_from_webapp=1&sender_device=pc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={img5}
              alt="TikTok"
              className="IMG2"
            />
          </Link>
        </div>

        <nav className="navbar navbar-expand-lg bg-water">
          <div className="container-fluid">
            <Link className="navbar-brand me-5" to="/">
              <img
                src={img6}
                alt="Logo"
                className="img-fluid me-5"
                style={{ maxHeight: "40px" }}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
              aria-controls="navbarNav"
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`collapse navbar-collapse d-flex justify-content-start align-items-center   `}
              id="navbarNav"
            >
              <ul className="navbar-nav hosting">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${isActive("/") ? "activeing" : ""}`}
                    to="/"
                  >
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/Advertise") ? "activeing" : ""
                    }`}
                    to="/Advertise"
                  >
                    ADVERTISE
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/Distribute") ? "activeing" : ""
                    }`}
                    to="/Distribute"
                  >
                    DISTRIBUTE
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/hall-of-fame") ? "activeing" : ""
                    }`}
                    to="/hall-of-fame"
                  >
                    SPOTLIGHT WALL
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/Contactus") ? "activeing" : ""
                    }`}
                    to="/Contactus"
                  >
                    CONTACT US
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/FAQSection") ? "activeing" : ""
                    }`}
                    to="/FAQSection"
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
              
            </div>
            <div className={` menu ${isMenuOpen ? "show" : "close"}`}>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${isActive("/") ? "activeing" : ""}`}
                    aria-current="page"
                    to="/"
                  >
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/Advertise") ? "activeing" : ""
                    }`}
                    to="/Advertise"
                  >
                    ADVERTISE
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/Distribute") ? "activeing" : ""
                    }`}
                    to="/Distribute"
                  >
                    DISTRIBUTE
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/hall-of-fame") ? "activeing" : ""
                    }`}
                    to="/hall-of-fame"
                  >
                    SPOTLIGHT WALL
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/Contactus") ? "activeing" : ""
                    }`}
                    to="/Contactus"
                  >
                    CONTACT US
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      isActive("/FAQSection") ? "activeing" : ""
                    }`}
                    to="/FAQSection"
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;

import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import "../css/Footer.css";
import img from "../images/social/facebook.png";
import img1 from "../images/social/x.png";
import img2 from "../images/social/linkedin.png";
import img3 from "../images/social/instagram.png";
import img4 from "../images/social/youtube.png";
import img5 from "../images/social/tiktok.png";
import { BASE_SERVER_URL } from "../utils/Api";
import { Link } from "react-router-dom";
import "../css/Banner.css";

const Footer = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const [loading, setLoading] = useState(false);


  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email) {
      setFormError(" email is required.");
      return;
    }


    if (!validateEmail(formData.email)) {
      setFormError("Please enter a valid email address.");
      return;
    }

    setFormError("");
    setFormSuccess("");
    setLoading(true);


    try {
      const apiUrl = `${BASE_SERVER_URL}/addnewsletter`;
      const response = await axios.post(apiUrl, formData);

      Swal.fire("Success", response.data.message, "success");
      setFormSuccess("Subscribe successfully!");
      // console.log("Form submitted:", response.data);

      setFormData({
        email: "",
      });
    } catch (error) {
      setFormError("An error occurred. Please try again.");
      Swal.fire("Error", "An error occurred. Please try again.", "error");

      // console.error("Error in subscribe form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <footer className="footer container-fluid">
      <div className="waves">
        <div className="wave" id="wave1"></div>
        <div className="wave" id="wave2"></div>
        <div className="wave" id="wave3"></div>
        <div className="wave" id="wave4"></div>
      </div>

      <div className="footer-container container-fluid">
        <div className="footerring row">
          <div className="col">
            <h4 style={{ fontWeight: 600 }}>
              Because access to water is a fundamental right
            </h4>
            <p>
              We invite you to help us spread our message by sharing our
              movement with your friends and family
            </p>
            <div className="row riding close-cols">
              <div className="footer-width1 col">
                <Link
                  to="/Contactus"
                  className="btn to-adjust_btn"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  CONTACT US
                </Link>
              </div>
              <div className="footer-width2 col">
                <p className="footer-ptag">
                  <Link
                    to="/Advertise"
                    className="fix-the_lines"
                    onClick={() => window.scrollTo(0, 0)}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Order FreeHydration Boxes
                  </Link>
                </p>
                <p className="footer-ptag">
                  <Link
                    to="/Advertise"
                    className="fix-the_lines"
                    onClick={() => window.scrollTo(0, 0)}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Order FreeHydration Cans
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col fix_thePostion">
            <div className="social-media-subscribeBtn">
              <div className="text-center p-1 social-media-icons">
                <Link
                  to="https://www.facebook.com/profile.php?id=61564981720392"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={img}
                    alt="Facebook"
                    className="Footer-IMG1"
                  />
                </Link>
                <Link
                  to="https://x.com/Free_Hydration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={img1}
                    alt="Twitter"
                    className="Footer-IMG2"
                  />
                </Link>
                <Link
                  to="https://www.linkedin.com/in/free-hydration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={img2}
                    alt="LinkedIn"
                    className="Footer-IMG2"
                  />
                </Link>
                <Link
                  to="https://www.instagram.com/free_hydration/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={img3}
                    alt="Instagram"
                    className="Footer-IMG2"
                  />
                </Link>
                <Link
                  to="https://www.youtube.com/@Free_Hydration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={img4}
                    alt="YouTube"
                    className="Footer-IMG2"
                  />
                </Link>
                <Link
                  to="https://www.tiktok.com/@free_hydration?is_from_webapp=1&sender_device=pc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={img5}
                    alt="TikTok"
                    className="Footer-IMG2"
                  />
                </Link>
              </div>

              <div className="div-for_text_and_btn">
                <p className="text-center">
                  <label htmlFor="email" className="text-light text-center">
                    Subscribe to Our Newsletter
                  </label>
                </p>

                <div className="conateae">
                  <form onSubmit={handleSubmit}>
                    <span style={{ marginRight: "10px" }}>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        className="handle-input-email"
                      />
                    </span>
                    <span>
                      <button
                        type="submit"
                        className="btn btn-subscribe"
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <Spinner animation="border" size="sm" />
                            <span className="ms-2">Subscribing...</span>
                          </>
                        ) : (
                          "Subscribe"
                        )}
                      </button>
                    </span>
                  </form>
                </div>
              </div>
              <div className="p-col-container">
                {" "}
                <p className="prox">
                  &copy;FreeHydration &nbsp; | All Rights Reserved &nbsp; |
                  &nbsp;
                  <Link
                    to="/Termscondition"
                    onClick={() => window.scrollTo(0, 0)}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Terms & Conditions
                  </Link>{" "}
                  &nbsp; | &nbsp;
                  <Link
                    to="/login"
                    onClick={() => window.scrollTo(0, 0)}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col col-md-2"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
